import React, { useState, useEffect } from 'react';
import { 
  TextField, Button, MenuItem, Typography, Paper, Box, 
  Select, InputLabel, FormControl, Checkbox, ListItemText,
  Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions,
  Snackbar, Alert
} from '@mui/material';
import axios from 'axios';
import juice from 'juice';

const prepareTemplate = (html, css) => {
  if (!html || !css) return html; // ???? html ??? css ???????????, ?????????? ?????? html
  return juice.inlineContent(html, css); // ?????????? ?????????? juice ??? ??????????????
};

const EmailSender = () => {
  // ????????? ?????
  const [emailData, setEmailData] = useState({
    campaignName: '',
    groups: [],
    senderName: '',
    senderEmail: '',
    subject: '',
    message: '',
    file: null,
  });

  // ??????, ??????????? ? ????
  const [groups, setGroups] = useState([]);
  const [verifiedEmails, setVerifiedEmails] = useState([]);
  const [templates, setTemplates] = useState([]);

  // ????????? ?????? (?? _id)
  const [selectedTemplateId, setSelectedTemplateId] = useState('');

  // ????????? ??? UX
  const [loading, setLoading] = useState(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [recipientsCount, setRecipientsCount] = useState(0);
  const [successSnackbarOpen, setSuccessSnackbarOpen] = useState(false);

  // ??? ?????? ??????? ????????? ??????
  useEffect(() => {
    fetchGroups();
    fetchVerifiedEmails();
    fetchTemplates();
  }, []);

  // ????????? ?????? ?????
  const fetchGroups = async () => {
    try {
      const response = await axios.get('/api/contacts/groups');
      setGroups(response.data); 
    } catch (error) {
      console.error('Failed to fetch groups', error);
    }
  };

  // ????????? ?????? ???????????????? ???????
  const fetchVerifiedEmails = async () => {
    try {
      const response = await axios.get('/api/emails');
      const verified = response.data
        .filter(email => email.isVerified)
        .map(email => email.email);
      setVerifiedEmails(verified);
    } catch (error) {
      console.error('Failed to fetch verified emails', error);
      alert('Failed to load verified sender emails. Please try again later.');
    }
  };

  // ????????? ?????? ????????
  const fetchTemplates = async () => {
    try {
      const response = await axios.get('/api/templates');
      setTemplates(response.data); // [{ _id, name, content }, ...]
    } catch (error) {
      console.error('Failed to fetch templates:', error);
    }
  };

  // ??????????? ????????? ?????
  const handleChange = (e) => {
    const { name, value } = e.target;
    setEmailData(prevData => ({ ...prevData, [name]: value }));
  };

  // ?????? ??? ?????
  const handleGroupChange = (event) => {
    const { value } = event.target;
    setEmailData(prevData => ({
      ...prevData,
      groups: typeof value === 'string' ? value.split(',') : value,
    }));
  };

  // ???????? ?????
  const handleFileChange = (e) => {
    setEmailData(prevData => ({ ...prevData, file: e.target.files[0] }));
  };

  // ????????? email-??????? ?? ????????? ?????
  const getEmailsFromGroups = async (selectedGroups) => {
    try {
      const emails = [];
      for (const groupName of selectedGroups) {
        const response = await axios.get(`/api/contacts/groups/${groupName}`);
        if (response.data && response.data.length > 0) {
          emails.push(...response.data);
        }
      }
      return emails;
    } catch (error) {
      console.error('Failed to fetch emails from groups:', error);
      return [];
    }
  };

  // ?????? ????? (????? Confirm-????????)
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!emailData.campaignName ||
        !emailData.senderName || 
        !emailData.senderEmail || 
        !emailData.subject || 
        !emailData.message || 
        emailData.groups.length === 0
    ) {
      alert('Please fill in all required fields.');
      return;
    }

    if (!verifiedEmails.includes(emailData.senderEmail)) {
      alert('The sender email is not verified. Please use a verified email.');
      return;
    }

    setLoading(true);
    const recipients = await getEmailsFromGroups(emailData.groups);
    setLoading(false);

    if (recipients.length === 0) {
      alert('No email addresses found in the selected groups.');
      return;
    }

    setRecipientsCount(recipients.length);
    setConfirmDialogOpen(true);
  };

  // ????????????? ????????
  const handleConfirmSend = async () => {
  setConfirmDialogOpen(false);
  setLoading(true);

  try {
    const recipients = await getEmailsFromGroups(emailData.groups);

    // ?????????? HTML ? inline-???????
    const combinedHtml = prepareTemplate(emailData.message, emailData.css);

    const data = {
      campaignName: emailData.campaignName,
      recipients: recipients,
      senderName: emailData.senderName,
      senderEmail: emailData.senderEmail,
      subject: emailData.subject,
      message: combinedHtml, // ????????? ? inline-???????
    };

    console.log('Sending data:', data);

    await axios.post('/api/send-email', data, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    setSuccessSnackbarOpen(true);

    // ????? ?????
    setEmailData({
      campaignName: '',
      groups: [],
      senderName: '',
      senderEmail: '',
      subject: '',
      message: '',
      css: '',
      file: null,
    });

    setSelectedTemplateId('');

  } catch (error) {
    console.error('Failed to send email:', error.response?.data || error.message);
    alert(`Failed to send email: ${error.response?.data?.error || error.message}`);
  } finally {
    setLoading(false);
  }
};

  // ?????? (??????? ??????)
  const handleCancelSend = () => {
    setConfirmDialogOpen(false);
  };

  // ???????? Snackbar
  const handleCloseSnackbar = () => {
    setSuccessSnackbarOpen(false);
  };

  // ????? ???????
const handleTemplateChange = (e) => {
  const templateId = e.target.value;
  setSelectedTemplateId(templateId);

  if (!templateId) {
    setEmailData((prev) => ({ ...prev, message: '', css: '' }));
    return;
  }

  const foundTemplate = templates.find((t) => t._id === templateId);
  if (foundTemplate) {
    const inlineHtml = prepareTemplate(foundTemplate.html, foundTemplate.css);
    setEmailData((prev) => ({
      ...prev,
      message: inlineHtml, // HTML ? inline-???????
      css: foundTemplate.css, // ????????? CSS
    }));
  } else {
    console.error(`Template with ID ${templateId} not found`);
  }
};

  return (
    <Paper elevation={3} style={{ padding: '20px', marginTop: '20px' }}>
      <Typography variant="h5" gutterBottom>
        Send Email
      </Typography>

      <form onSubmit={handleSubmit}>
        {/* Campaign Name */}
        <Box mb={2}>
          <TextField
            fullWidth
            label="Campaign Name"
            name="campaignName"
            value={emailData.campaignName}
            onChange={handleChange}
            variant="outlined"
          />
        </Box>

        {/* Groups */}
        <Box mb={2}>
          <FormControl fullWidth variant="outlined">
            <InputLabel>Groups</InputLabel>
            <Select
              multiple
              value={emailData.groups}
              onChange={handleGroupChange}
              label="Groups"
              renderValue={(selected) => {
                if (selected.length === 0) {
                  return 'No groups selected';
                }
                // ????????? ?????? ?????, ????? ????? ?????????
                const selectedGroups = groups.filter(g => selected.includes(g.groupName));
                // ????? ???????????
                const totalContacts = selectedGroups.reduce(
                  (sum, g) => sum + (g.subscribedCount || 0),
                  0
                );
                const groupNames = selectedGroups.map(g => g.groupName).join(', ');
                return `${groupNames} (Total: ${totalContacts})`;
              }}
            >
              {groups.length > 0 ? (
                groups.map((group) => (
                  <MenuItem key={group.groupName} value={group.groupName}>
                    <Checkbox checked={emailData.groups.indexOf(group.groupName) > -1} />
                    <ListItemText primary={`${group.groupName} (${group.subscribedCount || 0})`} />
                  </MenuItem>
                ))
              ) : (
                <MenuItem disabled>No groups found</MenuItem>
              )}
            </Select>
          </FormControl>
        </Box>
{/* Sender Name */}
        <Box mb={2}>
          <TextField
            fullWidth
            label="Sender Name"
            name="senderName"
            value={emailData.senderName}
            onChange={handleChange}
            variant="outlined"
          />
        </Box>
        {/* Sender Email */}
        <Box mb={2}>
          <FormControl fullWidth variant="outlined">
            <InputLabel>Sender Email</InputLabel>
            <Select
              name="senderEmail"
              value={emailData.senderEmail}
              onChange={handleChange}
              label="Sender Email"
            >
              {verifiedEmails.length > 0 ? (
                verifiedEmails.map((email) => (
                  <MenuItem key={email} value={email}>
                    {email}
                  </MenuItem>
                ))
              ) : (
                <MenuItem disabled>No verified emails found</MenuItem>
              )}
            </Select>
          </FormControl>
        </Box>

        {/* Subject */}
        <Box mb={2}>
          <TextField
            fullWidth
            label="Subject"
            name="subject"
            value={emailData.subject}
            onChange={handleChange}
            variant="outlined"
          />
        </Box>

        {/* ????? ??????? */}
        <Box mb={2}>
          <FormControl fullWidth variant="outlined">
            <InputLabel>Template</InputLabel>
            <Select
              label="Template"
              value={selectedTemplateId}
              onChange={handleTemplateChange}
            >
              <MenuItem value="">No template</MenuItem>
              {templates.map((tpl) => (
                <MenuItem key={tpl._id} value={tpl._id}>
                  {tpl.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        {/* Message (????? ????????????? ???????, ???? ???? ?????? ??????) */}
        <Box mb={2}>
          <TextField
            fullWidth
            label="Message (HTML)"
            name="message"
            value={emailData.message}
            onChange={handleChange}
            variant="outlined"
            multiline
            rows={6}
          />
        </Box>

        {/* ???????? ????? */}
        <Box mb={2}>
          <Button variant="contained" component="label">
            Upload File
            <input type="file" hidden onChange={handleFileChange} />
          </Button>
          {emailData.file && (
            <Typography variant="body2" style={{ marginTop: '10px' }}>
              {emailData.file.name}
            </Typography>
          )}
        </Box>

        {/* ?????? ???????? */}
        <Button 
          type="submit" 
          variant="contained" 
          color="primary" 
          disabled={loading}
        >
          {loading ? 'Processing...' : 'Send Email'}
        </Button>
      </form>

      {/* ?????? ????????????? ???????? */}
      <Dialog
        open={confirmDialogOpen}
        onClose={handleCancelSend}
      >
        <DialogTitle>Confirm Send</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Do you want to send email to {recipientsCount} recipients?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelSend} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmSend} color="primary" autoFocus>
            Send
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar ?? ???????? ???????? */}
      <Snackbar
        open={successSnackbarOpen}
        autoHideDuration={4000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert 
          onClose={handleCloseSnackbar} 
          severity="success"
          sx={{ width: '100%' }}
        >
          Email sent successfully!
        </Alert>
      </Snackbar>
    </Paper>
  );
};

export default EmailSender;
