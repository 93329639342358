import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import { AppBar, Toolbar, Typography, Button } from '@mui/material';
import axios from 'axios';

import PrivateRoute from './components/PrivateRoute';
import Login from './components/Login';
import Home from './components/Home';
import EmailSender from './components/EmailSender';
import ContactList from './components/ContactList';
import Security from './components/Security';
import Domains from './components/Domains';
import Statistics from './components/Statistics';
import Template from './components/Template';

function App() {
  // ????? ?? ?????? ????????? ??????????????, ?????????? ? ???????.
  const [authenticated, setAuthenticated] = useState(false);
  const [loadingAuth, setLoadingAuth] = useState(true);

  // ??? ?????? ???????/?????????? ???????? 
  // ????????? ?? ???????, ????????? ?? ???????????? (???? ?? ??????????? ??????)
  useEffect(() => {
    axios
      .get('/api/auth/check-session', { withCredentials: true })
      .then((res) => {
        if (res.data && res.data.authenticated) {
          setAuthenticated(true);
        } else {
          setAuthenticated(false);
        }
      })
      .catch((err) => {
        console.error('Failed to check session:', err);
        setAuthenticated(false);
      })
      .finally(() => {
        setLoadingAuth(false);
      });
  }, []);

  // ????????? ???????: ?????????? ?????? ?? ??????, ??? ???????????? ??????.
  const handleLogout = () => {
    axios
      .post('/api/auth/logout', {}, { withCredentials: true })
      .then(() => {
        setAuthenticated(false);
        window.location.href = '/'; 
      })
      .catch((err) => {
        console.error('Logout error:', err);
      });
  };

  // ???? ??? ?? ?????? ????????? ??????,
  // ????? ???????? "Loading..." ??? ???????
  if (loadingAuth) {
    return <div>Loading...</div>;
  }

  return (
    <Router>
      <div>
        <AppBar position="static">
          <Toolbar>
            <Typography variant="h6" style={{ flexGrow: 1 }}>
              Email Marketing
            </Typography>

            {/* ?????? "Home" ???????? ???? */}
            <Button color="inherit" component={Link} to="/">Home</Button>

            {/* ???? ???????????? ????????????????, ?????????? ?????????? ?????? ? ?????? Logout */}
            {authenticated && (
              <>
                <Button color="inherit" component={Link} to="/send-email">Send Email</Button>
                <Button color="inherit" component={Link} to="/contacts">Contact List</Button>
                <Button color="inherit" component={Link} to="/statistics">Statistics</Button>
                <Button color="inherit" component={Link} to="/domains">Domains</Button>
                <Button color="inherit" component={Link} to="/security">Security</Button>
                <Button color="inherit" component={Link} to="/template">Templates</Button>
                <Button
                  color="inherit"
                  sx={{
                    marginLeft: '10px',
                    backgroundColor: '#FFFFFF',
                    color: '#3f51b5',
                    '&:hover': {
                      backgroundColor: '#e0e0e0',
                    },
                  }}
                  onClick={handleLogout}
                >
                  Logout
                </Button>
              </>
            )}

            {/* ???? ?? ????????????????, ?????????? ?????? Login */}
            {!authenticated && (
              <Button
                color="inherit"
                component={Link}
                to="/login"
                sx={{
                  marginLeft: '10px',
                  backgroundColor: '#FFFFFF',
                  color: '#3f51b5',
                  '&:hover': {
                    backgroundColor: '#e0e0e0',
                  },
                }}
              >
                Login
              </Button>
            )}
          </Toolbar>
        </AppBar>

        <div style={{ padding: '20px' }}>
          <Routes>
            <Route path="/" element={<Home />} />

            {/* ????????? ????? */}
            <Route
              path="/send-email"
              element={<PrivateRoute element={<EmailSender />} isAuthenticated={authenticated} />}
            />
            <Route
              path="/contacts"
              element={<PrivateRoute element={<ContactList />} isAuthenticated={authenticated} />}
            />
            <Route
              path="/contacts/:groupName"
              element={<PrivateRoute element={<ContactList />} isAuthenticated={authenticated} />}
            />
            <Route
              path="/statistics"
              element={<PrivateRoute element={<Statistics />} isAuthenticated={authenticated} />}
            />
            <Route
              path="/domains"
              element={<PrivateRoute element={<Domains />} isAuthenticated={authenticated} />}
            />
            <Route
              path="/security"
              element={<PrivateRoute element={<Security />} isAuthenticated={authenticated} />}
            />
            <Route
              path="/template"
              element={<PrivateRoute element={<Template />} isAuthenticated={authenticated} />}
            />

            {/* ????????? ???? ??? ?????? */}
            <Route path="/login" element={<Login />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

// ????????? axios ??? ???????? ???? ?????? ??? ????????
axios.defaults.withCredentials = true;

export default App;
