import React, { useEffect, useRef } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button } from '@mui/material';
import grapesjs from 'grapesjs';
import presetNewsletter from 'grapesjs-preset-newsletter';
import 'grapesjs/dist/css/grapes.min.css';
import juice from 'juice';

const GrapesEditor = ({ open, onClose, template, onSave }) => {
  const editorRef = useRef(null);

  useEffect(() => {
    if (!open) return;

    let editorInitialized = false;

    const initializeEditor = () => {
      const container = document.getElementById('gjs');
      if (container) {
        editorInitialized = true;

        // Initialize GrapesJS editor
        const editor = grapesjs.init({
          container: '#gjs',
          plugins: [presetNewsletter],
          storageManager: false,
        });

        // Load existing template content or add default unsubscribe section if new
        if (template?.html && template?.css) {
          editor.setComponents(template.html); // Load HTML
          editor.setStyle(template.css); // Load CSS
        } else {
          editor.setComponents(`
  <div style="text-align: center; font-size: 12px; color: #555; margin-top: 20px; padding-top: 10px; border-top: 1px solid #ddd;">
    You are receiving this email because you subscribed to our newsletter. <br/>
    If you wish to unsubscribe, please click the button below. <br/>
    <a 
      href="https://mailsball.com/api/contacts/unsubscribe?campaignId={{campaignId}}&email={{userEmail}}" 
      style="display: inline-block; padding: 10px 20px; background-color: #f44336; color: white; text-decoration: none; border-radius: 5px; text-align: center; font-size: 14px;"
    >
      Unsubscribe
    </a>
  </div>
`);
        }

        // Store editor instance in ref
        editorRef.current = editor;
      } else {
        console.error('Container #gjs not found. Retrying...');
      }
    };

    // Retry mechanism to ensure container is available
    const retryInterval = setInterval(() => {
      if (editorInitialized) {
        clearInterval(retryInterval);
      } else {
        initializeEditor();
      }
    }, 100);

    return () => {
      clearInterval(retryInterval);
      if (editorRef.current) {
        editorRef.current.destroy();
        editorRef.current = null;
      }
    };
  }, [open, template]);

  const handleSave = () => {
  const editor = editorRef.current;
  if (!editor) {
    console.error('No GrapesJS editor instance found');
    return;
  }

  const rawHtml = editor.getHtml(); // ???????? HTML
  const rawCss = editor.getCss(); // ???????? CSS

  // ??????????? CSS ? inline-?????
  const htmlWithInlineStyles = juice.inlineContent(rawHtml, rawCss);

  const name = template?.name || 'New Template';
  onSave(name, htmlWithInlineStyles, rawCss); // ????????? inline HTML + raw CSS
};

  return (
    <Dialog open={open} fullWidth maxWidth="lg" onClose={onClose}>
      <DialogTitle>Edit Template</DialogTitle>
      <DialogContent>
        <div id="gjs" style={{ height: '500px' }} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSave} variant="contained" color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default GrapesEditor;
