import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Box,
  Button,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from '@mui/material';
import { Delete, Edit, FileCopy } from '@mui/icons-material';
import GrapesEditor from './GrapesEditor';

const Template = () => {
  const [templates, setTemplates] = useState([]);
  const [openEditor, setOpenEditor] = useState(false);
  const [currentTemplate, setCurrentTemplate] = useState(null);
  const [renameDialog, setRenameDialog] = useState(false);
  const [renameValue, setRenameValue] = useState('');

  // 1. ????????? ??????? ? ???? ??? ????????????
  useEffect(() => {
    fetchTemplates();
  }, []);

  const fetchTemplates = async () => {
    try {
      const response = await axios.get('/api/templates');
      setTemplates(response.data); 
      // ?????? ??????? [{ _id: "...", name: "...", content: "..." }, ...]
    } catch (error) {
      console.error('Failed to fetch templates:', error);
    }
  };

  // ??????? ????? (??????? GrapesEditor)
  const handleCreateTemplate = () => {
    setCurrentTemplate(null);
    setOpenEditor(true);
  };

  // ????????? ?????? (????? ??? ?????????????????)
  const handleSaveTemplate = async (name, html, css) => {
  const templateData = { name, html, css };

  try {
    if (currentTemplate) {
      // ??????????????
      await axios.put(`/api/templates/${currentTemplate._id}`, templateData);
    } else {
      // ???????? ?????? ???????
      await axios.post('/api/templates', templateData);
    }
    fetchTemplates(); // ????????? ?????? ????????
  } catch (error) {
    console.error('Error saving template:', error);
  }

  setOpenEditor(false);
};

  // ??????????? (???? ?????? ????????, ?? ?? ???????)
  const handleDuplicate = async (template) => {
  try {
    // ??????? ?????? ??? ????????????
    const duplicateTemplate = {
      name: `${template.name} (Copy)`,
      html: template.html, // ???????? HTML ???????
      css: template.css,   // ???????? CSS ???????
    };

    // ?????????? POST-?????? ?? ??????
    const response = await axios.post('/api/templates', duplicateTemplate);

    // ????????? ???????? ? ????????? ?????????
    setTemplates((prev) => [response.data, ...prev]);
  } catch (error) {
    console.error('Failed to duplicate template:', error);
  }
};

  // ??????? ?????? (? ?? ???????, ? ????????)
  const handleDelete = async (id) => {
    try {
      // ?????????? DELETE-??????
      await axios.delete(`/api/templates/${id}`);
      // ??????? ?? ?????????? ?????????
      setTemplates(prev => prev.filter(t => t._id !== id));
    } catch (error) {
      console.error('Failed to delete template:', error);
    }
  };

  // ????????????? (?????? ?????? ????????, ??? ???????)
  // ???? ?????? ???????? ? ?? ???????, ??????? axios.put(...) ?????
 const handleRename = async (id) => {
  try {
    // ???????? ??????? ??????
    const template = templates.find((t) => t._id === id);

    if (!template) {
      console.error(`Template with ID ${id} not found`);
      return;
    }

    // ????????? ?????? ??? ???????
    const updatedTemplate = {
      name: renameValue,
      html: template.html, // ??????? HTML ???????
      css: template.css,   // ??????? CSS ???????
    };

    // ?????????? PUT-??????
    await axios.put(`/api/templates/${id}`, updatedTemplate);

    // ????????? ????????? ?????????
    setTemplates((prev) =>
      prev.map((t) => (t._id === id ? { ...t, name: renameValue } : t))
    );

    setRenameDialog(false);
  } catch (error) {
    console.error('Failed to rename template:', error);
  }
};

  return (
    <Box>
      <Typography variant="h4" gutterBottom>
        Templates
      </Typography>
      <Button variant="contained" color="primary" onClick={handleCreateTemplate}>
        Create Template
      </Button>

      <TableContainer component={Paper} style={{ marginTop: '20px' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {templates.map(template => (
              // ?????????? template._id ??? key
              <TableRow key={template._id}>
                <TableCell>{template.name}</TableCell>
                <TableCell align="right">
                  <IconButton onClick={() => handleDuplicate(template)}>
                    <FileCopy />
                  </IconButton>
                  <IconButton
                    onClick={() => {
                      setCurrentTemplate(template);
                      setOpenEditor(true);
                    }}
                  >
                    <Edit />
                  </IconButton>
                  <IconButton onClick={() => handleDelete(template._id)}>
                    <Delete />
                  </IconButton>
                  <Button
                    onClick={() => {
                      setRenameDialog(true);
                      setRenameValue(template.name);
                      setCurrentTemplate(template);
                    }}
                  >
                    Rename
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* GrapesJS Editor Dialog */}
      {openEditor && (
        <GrapesEditor
          open={openEditor}
          onClose={() => setOpenEditor(false)}
          template={currentTemplate}
          onSave={handleSaveTemplate}
        />
      )}

      {/* Rename Dialog */}
      <Dialog open={renameDialog} onClose={() => setRenameDialog(false)}>
        <DialogTitle>Rename Template</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            value={renameValue}
            onChange={e => setRenameValue(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setRenameDialog(false)}>Cancel</Button>
          {/* handleRename ???????? ????????? rename */}
          <Button onClick={() => handleRename(currentTemplate._id)}>Save</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Template;
